@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Poppins";
  src: url("./assets/font/Poppins/Poppins-Light.ttf");
  font-weight: 300;
}
@font-face {
  font-family: "Poppins";
  src: url("./assets/font/Poppins/Poppins-Regular.ttf");
  font-weight: normal;
}
@font-face {
  font-family: "Poppins";
  src: url("./assets/font/Poppins/Poppins-Medium.ttf");
  font-weight: 500;
}
@font-face {
  font-family: "Poppins";
  src: url("./assets/font/Poppins/Poppins-SemiBold.ttf");
  font-weight: 600;
}
@font-face {
  font-family: "Poppins";
  src: url("./assets/font/Poppins/Poppins-Bold.ttf");
  font-weight: 700;
}
@font-face {
  font-family: "Poppins";
  src: url("./assets/font/Poppins/Poppins-ExtraBold.ttf");
  font-weight: 800;
}

/* Space Grotesk Font */
@font-face {
  font-family: "Space Grotesk";
  src: url("./assets/font/SpaceGrotesk/SpaceGrotesk-Bold.ttf");
  font-weight: 700;
}
/* Great Vibes Font */
@font-face {
  font-family: "GreatVibes";
  src: url("./assets/font/Great_Vibes/GreatVibes-Regular.ttf");
  font-weight: 400;
}

.spaceGroteskText {
  font-family: "Space Grotesk";
}

.greatVibesText {
  font-family: "GreatVibes";
}

.maxContent {
  width: max-content;
}

html {
  background-color: #262471 !important;
  scroll-behavior: smooth;
  scrollbar-width: thin;
}

* {
  font-family: "Poppins";
  font-size: 0.95rem;
}

.shadow-top-right {
  box-shadow: 4px -4px 4px 0px rgba(0, 0, 0, 0.25);
  -webkit-box-shadow: 4px -4px 4px 0px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 4px -4px 4px 0px rgba(0, 0, 0, 0.25);
}

.shadow-bottom-left {
  box-shadow: -4px 4px 4px 0px rgba(0, 0, 0, 0.25);
  -webkit-box-shadow: -4px 4px 4px 0px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: -4px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.heroBg {
  background-image: url(./assets/images/heroBg.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 -100px;
}

.experienceBg {
  background-image: url(./assets/images/eperienceBg.png);
  background-repeat: no-repeat;
  background-size: cover;
  /* background-position: 0 -150px; */
}

.aboutBg {
  background-image: url(./assets/images/aboutBg.png);
  background-repeat: no-repeat;
  background-size: cover;
  /* background-position: 0 -150px; */
}

.arrowAssetBg {
  background-color: #262471;
  background-image: url("./assets/images/arrow-asset.svg");
  background-repeat: no-repeat;
  background-size: cover;
}

/* Who we are */
.customerServiceBg {
  background-image: url(./assets/images/customerService.png);
  background-repeat: no-repeat;
  background-size: cover;
  /* background-position: 0 -150px; */
}
.communicationBg {
  background-image: url(./assets/images/communicationBg.png);
  background-repeat: no-repeat;
  background-size: cover;
  /* background-position: 0 -150px; */
}
.reliabilityBg {
  background-image: url(./assets/images/reliabilityBg.png);
  background-repeat: no-repeat;
  background-size: cover;
  /* background-position: 0 -150px; */
}
.passengerSafetyBg {
  background-image: url(./assets/images/passengerSafety.png);
  background-repeat: no-repeat;
  background-size: cover;
  /* background-position: 0 -150px; */
}
.punctualityBg {
  background-image: url(./assets/images/punctuality.png);
  background-repeat: no-repeat;
  background-size: cover;
  /* background-position: 0 -150px; */
}
.privateJetBg {
  background-image: url(./assets/images/concierge/privateJet.png);
  background-repeat: no-repeat;
  background-size: cover;
  /* background-position: 0 -150px; */
}
.helicopterBg {
  background-image: url(./assets/images/concierge/helicopterBg.png);
  background-repeat: no-repeat;
  background-size: cover;
  /* background-position: 0 -150px; */
}
.yatchBg {
  background-image: url(./assets/images/concierge/yatchBg.png);
  background-repeat: no-repeat;
  background-size: cover;
  /* background-position: 0 -150px; */
}
.villaBg {
  background-image: url(./assets/images/concierge/villaBg.png);
  background-repeat: no-repeat;
  background-size: cover;
  /* background-position: 0 -150px; */
}
.luxuryHotelsBg {
  background-image: url(./assets/images/concierge/luxuryHotelsBg.png);
  background-repeat: no-repeat;
  background-size: cover;
  /* background-position: 0 -150px; */
}
.restaurantBg {
  background-image: url(./assets/images/concierge/restaurantBg.png);
  background-repeat: no-repeat;
  background-size: cover;
  /* background-position: 0 -150px; */
}
.personalShoppingBg {
  background-image: url(./assets/images/concierge/personalShoppingBg.png);
  background-repeat: no-repeat;
  background-size: cover;
  /* background-position: 0 -150px; */
}
.propertyBg {
  background-image: url(./assets/images/concierge/propertyBg.png);
  background-repeat: no-repeat;
  background-size: cover;
  /* background-position: 0 -150px; */
}

.luxuryBg {
  background-image: url(./assets/images/luxuryBg.png);
  background-repeat: no-repeat;
  background-size: cover;
  /* background-position: 0 -150px; */
}
.voaBg {
  background-image: url(./assets/images/voa.png);
  background-repeat: no-repeat;
  background-size: cover;
  /* background-position: 0 -150px; */
}
.conciergeBg {
  background-image: url(./assets/images/concierge.png);
  background-repeat: no-repeat;
  background-size: cover;
  /* background-position: 0 -150px; */
}
.corporateTravelBg {
  background-image: url(./assets/images/corporateTravelBg.png);
  background-repeat: no-repeat;
  background-size: cover;
  /* background-position: 0 -150px; */
}
.weddingServicesBg {
  background-image: url(./assets/images/weddingServicesBg.png);
  background-repeat: no-repeat;
  background-size: cover;
  /* background-position: 0 -150px; */
}
.conciergeBg2 {
  background-image: url(./assets/images/concierge/concierge2.png);
  background-repeat: no-repeat;
  background-size: cover;
  /* background-position: 0 -150px; */
}

/* Partnership */
.becomeAPartnerBg {
  background-image: url(./assets/images/becomeAPartner.png);
  background-repeat: no-repeat;
  background-size: cover;
  /* background-position: 0 -150px; */
}

/* Partnership */
.driveForShuttlelaneBg {
  background-image: url(./assets/images/driver/signup/driveForShuttlelane1.png);
  background-repeat: no-repeat;
  background-size: cover;
  /* background-position: 0 -150px; */
}

/* Driver Signup */
.driverSignupBg {
  background-image: url(./assets/images/driver/signup/driveForShuttlelane.png);
  background-repeat: no-repeat;
  background-size: cover;
  /* background-position: 0 -150px; */
}

/* Vendor Signup */
.vendorSignupBg {
  background-image: url(./assets/images/vendor/vendorSignup.png);
  background-repeat: no-repeat;
  background-size: cover;
  /* background-position: 0 -150px; */
}

.formWidthFull {
  width: 100%;
  transition: width 1s;
}

.formWidthShrink {
  width: 60%;
  transition: width 1s;
}

.css-13cymwt-control {
  border: none !important;
}

.css-1hb7zxy-IndicatorsContainer {
  display: none !important;
}

/* NAVBAR ANIMATION */

.rec,
.rec2 {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  transform: translateX(-100%);
  transition: transform 0.5s ease-in-out;
  overflow: hidden;
  z-index: 100;
}

.rec {
  display: flex;
  justify-content: end;
}

.animateFirstRec {
  animation: slideOut 0.15s forwards;
}

.animateSecondRec {
  animation: slideOut2 0.2s forwards;
  animation-delay: 0.15s;
}

.animateFirstRecReverse {
  animation: slideOutReverse 0.2s forwards;
  animation-delay: 0.2s;
}

.animateSecondRecReverse {
  animation: slideOut2Reverse 0.3s forwards;
}

@keyframes slideOut {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0%);
  }
}

@keyframes slideOut2 {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(-5%);
  }
}

@keyframes slideOutReverse {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(-100%);
  }
}

@keyframes slideOut2Reverse {
  from {
    transform: translateX(-5%);
  }
  to {
    transform: translateX(-100%);
  }
}

/* Custom Scrollbar */
.shuttlelaneScrollbar::-webkit-scrollbar {
  width: 1px;
}
.shuttlelaneScrollbar::-webkit-scrollbar-thumb {
  background-color: #262471;
  border-radius: 30px;
  height: 10px;
}

/* Custom Scrollbar */
.shuttlelaneScrollbarHoriz::-webkit-scrollbar:horizontal {
  height: 1px;
}
.shuttlelaneScrollbarHoriz::-webkit-scrollbar-thumb:horizontal {
  background-color: #262471;
  border-radius: 30px;
  height: 2px;
}

/* Apex Charts Custom Rules */
tspan {
  font-size: 0.75rem !important;
}

/* React Modal Custom Rules */
.ReactModal__Overlay.ReactModal__Overlay--after-open {
  z-index: 99;
}

/* React Select Custom Rules */
.selectContainer > .css-b62m3t-container {
  width: 100% !important;
}

/* React Flag Input Custom Rules */
.flag-dropdown {
  background-color: transparent !important;
  border: none !important;
}

input.form-control {
  border: none !important;
  padding-top: 0 !important;
  height: 100% !important;
}

/* Toast Container Custom Rules */
.Toastify__toast-container {
  z-index: 9999 !important;
}

/* All round box shadow */
.allRoundBoxShadow {
  box-shadow: 0px 1px 7px 4px rgba(0, 0, 0, 0.03);
}

/* RSuite DatePicker Style Rules (For update booking) */
.rs-anim-fade.rs-anim-in.placement-bottom-start.rs-picker-date-menu.rs-picker-menu {
  z-index: 99 !important;
}

/* Avoid zoom in on input components on mobile */
input[type="text"],
input[type="password"],
input[type="email"],
input[type="number"],
textarea {
  font-size: 16px !important;
}
